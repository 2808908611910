// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

import UserPool from '../helpers/UserPool';

type MyLoginState = {
    isFirstLogin: boolean;
    user: CognitoUser;
    userAttr: any
};

export default class CognitoAPIClient {
    
    loginState: MyLoginState = {
        isFirstLogin: false,
        user: new CognitoUser({Username: '', Pool: UserPool}),
        userAttr: {}
    };

    onError: (error: any) =>  void;

    constructor(onError: (error: any) => void) {
        this.onError = onError;
    }

    login(username: string, password: string) : Promise<Object> {
        const authenticationData = {
            Username: username,
            Password: password
        };

        const cognitoUser = new CognitoUser({ Username: username, Pool: UserPool });
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    let data = {
                        type: 'TOKEN_DATA',
                        refreshToken: result.getRefreshToken().getToken(),
                        accessToken: result.getAccessToken().getJwtToken(),
                        accessTokenExpiresAt: result.getAccessToken().getExpiration(),
                        idToken: result.getIdToken().getJwtToken(),
                        idTokenExpiresAt: result.getAccessToken().getExpiration()
                    };
                    
                    this.loginState = {
                        isFirstLogin: false,
                        user: cognitoUser,
                        userAttr: {}
                    };

                    resolve(data);
                },
                onFailure: (err) => {
                  reject(err);
                },
                newPasswordRequired: (userAttributes) => {
                    // let data = {
                    //     type: 'PWD_CHALLENGE', 
                    //     isFirstLogin: true,
                    //     user: cognitoUser,
                    //     userAttr: userAttributes
                    // };
                    //
                    // ToDo: Implement password challenge
                    reject('to be implemented');
                },
            });
        });
    }

    isAuthenticated() {
        if (this.loginState.user.getUsername()!=='') {
            return true;
        } else {
            return false;
        }
    }

    logout() : Promise<Object> {
        return new Promise((resolve, reject) => {
            if(this.loginState.user.getUsername()!=='') {
                this.loginState.user.globalSignOut({
                    onSuccess: (msg: string) => {
                        resolve(msg);
                    },
                    onFailure: (err: Error) => {
                        reject(err);
                    }
                });
            } else reject(new Error('Logout fucked up ..'));
        });
    }
}
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
    UserPoolId: 'eu-central-1_quWbk6xa7',
    ClientId: '3kbfka2u73icl0phj1kt1vm6g3'
}

const UserPool = new CognitoUserPool(poolData);


export default UserPool;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import WelcomPage from './pages/WelcomePage';
import RegistrationPage from './pages/RegistrationPage';
import LoginPage from './pages/LoginPage';

import FlashProvider from './contexts/FlashProvider';
import ApiProvider from './contexts/ApiProvider';
import UserProvider from './contexts/UserProvider';

import PublicRoute from './components/publicRoute/PublicRoute';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Dashboard from './pages/Dashboard';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <FlashProvider>
          <ApiProvider>
            <UserProvider>
              <Routes>
                <Route path="/" element={
                    <PublicRoute><WelcomPage /></PublicRoute>} />
                <Route path="/register" element={
                    <PublicRoute><RegistrationPage /></PublicRoute>} />
                <Route path="/login" element={
                    <PublicRoute><LoginPage /></PublicRoute>} />
                <Route path="*" element={
                  <PrivateRoute>
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </PrivateRoute>
                } />
              </Routes>
            </UserProvider>
          </ApiProvider>
        </FlashProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

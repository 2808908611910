// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import React, { createContext, useContext, useState, useEffect } from "react";

import { IUser, CurrentUserContextType } from "../types/user";
import { useApi } from "./ApiProvider";
import { useNavigate } from "react-router-dom";
import { useFlash } from "./FlashProvider";

interface IProps {
    children: React.ReactNode
};

type PromiseDataType = {
    type?: string;
    refreshToken?: string;
    accessToken?: string;
    accessTokenExpiresAt?: any;
    idToken?: string;
    idTokenExpiresAt?: any;
};

const UserContext = createContext<CurrentUserContextType | null>(null);

const UserProvider: React.FunctionComponent<IProps> = ( props: IProps ) => {
    
    const [currentUser, setCurrentUser] = useState<IUser | null | undefined>(undefined);
    const api = useApi();
    const flash = useFlash()
    const navigate = useNavigate();
    
    useEffect(() => {
            setCurrentUser(null);
    },[])

    const login = (username: string, password: string) : Promise<any> => {
        const promise: Promise<Object> = api.login(username, password);
        
            promise.then((data: PromiseDataType) => {
                const { idToken } = data;
                
                if(idToken) {
                    setCurrentUser({user: username, token: idToken});
                    navigate('/Dashboard');
                } else {
                    flash('Token Fucked Up .. thanks AWS', 'danger');
                    setCurrentUser(null);
                }
                
            },(err) => {
                flash('Du kummst hier net rein', 'danger');
                setCurrentUser(null);
            });
            return promise;
    };

    const logout = () : Promise<any> => {
        const promise: Promise<Object> = api.logout();

        promise.then((msg) => {
            setCurrentUser(null);
            navigate('/');
        }, (err) => {
            flash(err.message, 'danger');
        });

        return promise;

    }

    return (
        <UserContext.Provider value={{ currentUser, login, logout }}>
            {props.children}
        </UserContext.Provider>
    )
}
export default UserProvider;

export function useUser() {
    return useContext(UserContext);
}
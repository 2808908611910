// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useLocation, Navigate } from "react-router-dom";
import { useUser } from "../../contexts/UserProvider";

const PrivateRoute = ({ children }: React.PropsWithChildren<{}>) => {
    const user = useUser();
    const location = useLocation();

    if (user?.currentUser === undefined) {
        return null;
    } else if (user?.currentUser){
        return <>{children}</>;
    }
    
    const url = location.pathname + location.search + location.hash;
    return <Navigate to='/login' state={{ next: url }} />;
}

export default PrivateRoute;
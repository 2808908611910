// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useNavigate } from 'react-router-dom';

const SignButtons = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    }

    const handleSignUp = () => {
        navigate('/register');
    }

    return (
        <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
            <button className="btn btn-outline-primary btn-sm" onClick={handleLogin}>
                Sign In
            </button>
            <button className="btn btn-primary btn-sm" onClick={handleSignUp}>
                Sign Up
            </button>
        </div>
    )
}

export default SignButtons;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer: React.FunctionComponent = () => {
    return(
        <div className='container'>
            <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top'>
                <div className='col-md-4 d-flex align-items-center'>
                    <a href='/' className='mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1'>
                        <i className='bi bi-arrow-clockwise' />
                    </a>
                    <span className='text-muted'>© 2023 Brenckmann</span>
                </div>
            </footer>
        </div>
    )
}

export default Footer;
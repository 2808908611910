// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { Container, Stack } from 'react-bootstrap';
import FlashMessage from '../components/flashMessage/FlashMessage';

interface Props {
    children: React.ReactNode
}

// Body can be used to add a sidebar. Details in https://blog.miguelgrinberg.com/post/the-react-mega-tutorial-chapter-3-working-with-components

const Body: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Container>
            <Stack direction="horizontal" className="Body">
                <Container className="Content">
                    <FlashMessage />
                    {props.children}
                </Container>
            </Stack>
        </Container>
    )
}

export default Body;
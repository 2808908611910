// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useContext } from "react";
import { Alert, Collapse } from "react-bootstrap";

import { FlashContext } from "../../contexts/FlashProvider";
import { FlashContextType } from "../../types/flashContext";

export default function FlashMessage() {
    const { flashMessage, visible, hideFlash } = useContext(FlashContext) as FlashContextType;
    return (
        <Collapse in={visible}>
            <div>
                <Alert 
                    variant={flashMessage.type || 'info'} 
                    show={true} dismissible
                    onClose={hideFlash}
                    data-visible={visible}
                >
                        {flashMessage.message}
                    </Alert>
            </div>
        </Collapse>
    );
}
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import MainLayout from '../layout/MainLayout';

const WelcomePage = () => {
    return (
        <MainLayout >
          <div className="container">
            Hello CDK World
          </div>
      </MainLayout>
    )
}

export default WelcomePage;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

type functionProps = {
    handleLogin: (username: string, password: string) => void
};

const SignInForm = ({handleLogin}: functionProps) => {

    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    
    
    const handleChange = (event: any) => {

        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();

        setValidated(true);
        setIsLoading(true);
        
        try {
            handleLogin(values.email, values.password);
            setIsLoading(false);
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    const validateForm = () => {
        
        return values.email.length > 0 
            && values.password.length > 0
    }

        // use success@simulator.amazonses.com email address to test with mail simulator 
        // https://docs.aws.amazon.com/ses/latest/dg/send-an-email-from-console.html#send-email-simulator

    return(
        <div className="mt-3 mb-3">
            <Form validated={validated} onSubmit={onSubmit}>
                <h2 className='fw-bold mb-5 mt-md-4 text-center'>Anmeldung</h2>
                <Form.Group controlId="signInForm.formPlaintextEmail" className="mb-3 InputField">
                    <Form.Label>E-Mail Adresse als Login</Form.Label>
                    <Form.Control
                        autoFocus
                        name="email"
                        type="email"
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Bitte geben Sie eine gültige E-Mail Adresse ein.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="signInForm.formPlaintextPassword" className="mb-3 InputField">
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control
                        name='password'
                        type="password"
                        onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Bitte geben Sie ein Passwort ein.
                    </Form.Control.Feedback>
                </Form.Group>

                <Button
                    className='btn btn-primary w-100 mt-4'
                    type='submit'
                    disabled={ ! validateForm() || isLoading }
                >
                    { ! isLoading ? 'Login' : 'Signing in ...' }
                </Button>
            </Form>
        </div>
    )

}

export default SignInForm;
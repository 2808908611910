// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useState, createRef } from 'react';
import { Form, Button } from 'react-bootstrap';

import InputField from '../../components/inputfield/InputField';
// import UserPool from '../../helpers/UserPool';
import { useNavigate } from 'react-router-dom';

interface FormObject {
    email?: string;
    password?: string;
    password2?: string;
}

interface SubmitObject {
    email: string;
    password: string;
}

const SignUpForm = () => {
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState<FormObject>({});
    // const [formValues, setFormValues] = useState<SubmitObject>({email:"",password:""});
    
    const emailField = createRef<HTMLInputElement>();
    const passwordField = createRef<HTMLInputElement>();
    const password2Field = createRef<HTMLInputElement>();

    // useEffect(() => {
    //     if (emailField.current) {
    //         emailField.current.focus();
    //     }
    // }, []);

    const onSubmit = async (event: any) => {
        event.preventDefault();

        const errors: FormObject = {};
        const currentValues : SubmitObject =  {email: "", password: ""};
        if (emailField.current && emailField.current.value !== "") {
            currentValues.email = emailField.current.value;
        } else {
            errors.email = 'Bitte eine Email Adresse eingeben.';
        }
    
        if (passwordField.current && passwordField.current.value !== "") {
            currentValues.password = passwordField.current.value;
        } else {
            errors.password = 'Bitte geben Sie ein Passwort ein.';
        }
    
        if (password2Field.current && password2Field.current.value !== "") {
            if (password2Field.current.value !== currentValues.password) {
                errors.password2 = 'Die eingegebenen Passwörter unterscheiden sich.'
            }
        } else {
            errors.password2 = 'Bitte geben Sie das Passwort zur Kontroller erneut ein.'
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        } else {
            // User Signup is disabled during development
            // create users in AWS Cognito console
            //
            // setFormValues(currentValues);
            // UserPool.signUp(
            //     formValues.email,
            //     currentValues.password,
            //     [],
            //     [],
            //     (err, data) => {
            //         if(err) {
            //             console.log(err);
            //         } else {
            //             setFormErrors({});
            //             navigate("/");
            //         }
            //     }
            // )

            // Remove the next two lines when activating user sign up
            //
            setFormErrors({});
            navigate("/");
        }

        // use success@simulator.amazonses.com email address to test with mail simulator 
        // https://docs.aws.amazon.com/ses/latest/dg/send-an-email-from-console.html#send-email-simulator
    };

    return(
        <div className="mt-3 mb-3">
            <Form onSubmit={onSubmit}>
                <Form.Group
                    className="mb-3"
                    controlId="registrationForm.formPlaintextEmail"
                >
                    <InputField
                        name="email" 
                        label="Email Adresse als Login"
                        error={formErrors.email} 
                        fieldRef={emailField} 
                    />
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="registrationForm.formPlaintextPassword"
                >
                    <InputField
                        name="password"
                        label="Passwort"
                        type="password"
                        error={formErrors.password} 
                        fieldRef={passwordField}
                    />
                </Form.Group>

                <Form.Group
                    className="mb-3"
                    controlId="registrationForm.formPlaintextPassword"
                >
                    <InputField
                        name="password2"
                        label="Passwortwiederholung"
                        type="password"
                        error={formErrors.password2} 
                        fieldRef={password2Field}
                    />
                </Form.Group>

                <Button
                    className='btn btn-primary w-100 mt-4'
                    type='submit'
                >
                    Submit
                </Button>
            </Form>
        </div>
    )

}

export default SignUpForm;
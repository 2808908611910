import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useFlash } from "./FlashProvider";
import CognitoAPIClient from "../api/CognitoAPIClient";

const ApiContext = createContext<CognitoAPIClient | null>(null);

export default function ApiProvider({ children }: React.PropsWithChildren<{}>) {
    const flash = useFlash();

    const onError = useCallback(() => {
        flash('An unexpected error has occurred. Please try again later.', 'danger');
    }, [flash]);

     const api = useMemo(() => new CognitoAPIClient(onError), [onError]);
    //const api = new CognitoAPIClient(onError);

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
}

export function useApi() {
    return useContext(ApiContext) as CognitoAPIClient;
}
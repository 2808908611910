// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { useEffect, useState } from "react";
import { Image, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useUser } from "../../contexts/UserProvider";
import SignButtons from "../signButtons/SignButtons";

const Header = () => {
    const user = useUser();
    const [isLoggedIn, setisloggedin] = useState(false);

    useEffect(() => {
        setisloggedin(user?.currentUser ? true : false);
    },[user?.currentUser])

    const handleLogout = () => {
        user?.logout();
    }

    return(
        <div>
            <nav className='navbar navbar-expand-lg bg-light'>
                <div className='container-fluid'>
                    <NavLink className="navbar-brand" to='/'>
                        Pflegekosten Manager
                    </NavLink>
                    <button
                        className='navbar-toggler'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                        <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/dashboard'>
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link disabled" to="/">
                                    Future Stuff
                                </NavLink>
                            </li>
                        </ul>  

                        {isLoggedIn ? (
                            <div className="justify-content-end">
                                <NavDropdown title={
                                    //todo: replace with user image src={user.avatar_url + '&s=32'}
                                    <Image src={'https://gravatar.com/avatar/1e5fb3195dd2f0ec2b0ac3394786ab09?s=400&d=robohash&r=x&s=32'} roundedCircle />
                                    } align="end">
                                    {/* todo: implement user profile page and activate link */}
                                    <NavDropdown.Item /*as={NavLink} to={'/user/' + user.username}*/ >
                                        Profile
                                    </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    Logout
                                </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                           
                        ) : (
                            <SignButtons />
                        )}
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import React from 'react';  

import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

interface Props {
  children: React.ReactNode
}

const MainLayout: React.FunctionComponent<Props> = (props: Props) => {
    return (
      <div>
        <Header />
          {props.children}
        <Footer />
      </div>
    )
  }
  
  export default MainLayout;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { Row, Col, Card } from 'react-bootstrap';

import Body from '../layout/Body';
import MainLayout from '../layout/MainLayout';
import SignInForm from '../forms/signInForm/SignInForm';
// import NewPasswordForm from '../forms/newPasswordForm/NewPasswordForm';

import { useUser } from '../contexts/UserProvider';

const LoginPage = () => {
    const user = useUser();

    const handleLogin = (username: string, password: string) => {        
        user?.login(username, password);
    }

    return (
        <MainLayout>
            <Body>
                <div className='Container'>
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={8} lg={6} xs={12}>
                            <Card className="shadow px-4">
                                <Card.Body>
                                    {/* {loginState.isFirstLogin ? (
                                        <NewPasswordForm changePassword={changePassword}/>
                                    ) : (
                                        <SignInForm handleLogin={handleLogin}/>
                                    )} */}
                                    <SignInForm handleLogin={handleLogin}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Body>
        </MainLayout>
    )

}

export default LoginPage;
// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>
import { Row, Col, Card } from 'react-bootstrap';


import Body from '../layout/Body';
import MainLayout from '../layout/MainLayout';
import SignUpForm from '../forms/signUpForm/SignUpForm';



const RegistrationPage = () => {

    return (
        <MainLayout>
            <Body>
                <div className='Container'>
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={8} lg={6} xs={12}>
                            <Card className="shadow px-4">
                                <Card.Body>
                                    <h2 className='fw-bold mb-5 mt-md-4 text-center'>Registrierung</h2>
                                    <SignUpForm />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Body>
        </MainLayout>
    )

}

export default RegistrationPage;
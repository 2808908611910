// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/UserProvider";
import React from "react";

export default function PublicRoute({ children }: React.PropsWithChildren<{}>) {
    const user = useUser();
    if (user?.currentUser === undefined) {
        return null;
    } else if (user?.currentUser) {
        return <Navigate to="/dashboard" />;
    } else {
        return <>{children}</>;
    }
};
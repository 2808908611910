// © 2023 Ingo Brenckmann <ingo.brenckmann@gmail.com>

import MainLayout from '../layout/MainLayout';

const Dashboard = () => {
    return (
        <MainLayout >
          <div className="container">
            Dashboard
          </div>
      </MainLayout>
    )
}

export default Dashboard;